// action constants
import { CommonActionTypes, CommonStateTypes } from "./constants";

// actions
import { CommonActionType } from "./actions";

const INIT_STATE = {
  currentShop: null,
  urlShopName: "",
  shops: [],
  socket: null,
};

const Common = (
  state: CommonStateTypes = INIT_STATE,
  action: CommonActionType<string | boolean | null>
) => {
  switch (action.type) {
    case CommonActionTypes.CURRENT_SHOP:
      return {
        ...state,
        currentShop: action.payload,
      };
    case CommonActionTypes.URL_SHOP_NAME:
      return {
        ...state,
        urlShopName: action.payload,
      };
    case CommonActionTypes.GET_SHOPS:
      return {
        ...state,
        shops: action.payload,
      };
    case CommonActionTypes.RESET:
      return {
        ...state,
        shops: [],
        urlShopName: [],
        currentShop: null,
      };
    case CommonActionTypes.SAVE_SOCKET:
      return {
        ...state,
        socket: action.payload,
      };
    default:
      return state;
  }
};

export default Common;
