import React, { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import logo from '../../assets/images/logo-bg.png';
import config from '../../config';

interface AccountLayoutProps {
    bottomLinks?: any;
    children?: any;
    shopImage?: string;
}

const AuthLayout2 = ({ bottomLinks, children, shopImage }: AccountLayoutProps) => {

    useEffect(() => {
        if (document.body) document.body.classList.add('authentication-bg');

        return () => {
            if (document.body) document.body.classList.remove('authentication-bg');
        };
    }, []);

    return (
        <>
            <div className="account-pages my-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col xl={10}>
                            <Card>
                                <Card.Body className="p-0">
                                    <Row className="g-0">
                                        <Col lg={6} className="p-4 d-flex align-items-center justify-content-center">
                                            <img src={config.IMAGE_URL + shopImage} alt="" style={{ width: '70%' }} />
                                        </Col>
                                        <Col lg={6} className="p-4 d-flex flex-column justify-content-center">
                                            {children}
                                        </Col>

                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* bottom links */}
                            {bottomLinks}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default AuthLayout2;
