import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// apicore
import { APICore, setAuthorization } from "../../helpers/api/apiCore";

// helpers
import {
  login as loginApi,
  logout as logoutApi,
  signup as signupApi,
  forgotPassword as forgotPasswordApi,
} from "../../helpers/";

// actions
import { setCurrentShop } from "./actions";

// constants
import { CommonActionTypes } from "./constants";
import { GET_SHOP } from "../../apis/shops";

interface ShopData {
  payload: {
    name: string;
  };
  type: string;
}

const api = new APICore();

function* getShop({
  payload: { name },
  type,
}: ShopData): SagaIterator {
  try {
    const response = yield call(GET_SHOP, name);
    const shop = response.data;
    yield put(setCurrentShop(shop));
  } catch (error: any) {
    console.log("");
  }
}

export function* watchGetShop() {
  yield takeEvery(CommonActionTypes.GET_SHOP, getShop);
}

function* commonSaga() {
  yield all([fork(watchGetShop)]);
}

export default commonSaga;
