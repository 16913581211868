import { useEffect, useState } from 'react';
import { Button, Alert, Row, Col } from 'react-bootstrap';
import { VerticalForm } from '../../components';
import AuthLayout2 from './AuthLayout2';
import { useSnackbar } from 'notistack';
import { GET_EVALUATION_ORDER, EVALUATE_ORDER } from '../../apis/orders';
import { CircularProgress, LinearProgress } from '@mui/material';
import Select from 'react-select'
import ReactStars from "react-rating-stars-component";
import moment from 'moment';

const RateOrder = (props) => {

    useEffect(() => {
        let token = props.match?.params?.token
        if (token) {
            getOrder(token)
        } else {
            setTokenExpired(true)
        }
    }, []);

    const { enqueueSnackbar } = useSnackbar()
    const [order, setOrder] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const [isOrderRated, setOrderRated] = useState(false)
    const [isTokenExpired, setTokenExpired] = useState(false)
    const [isUpdating, setUpdating] = useState(false)
    const [quality, setQuality] = useState(0)
    const [delivery, setDelivery] = useState(0)
    const [name, setName] = useState(null)
    const [comment, setComment] = useState("")

    async function getOrder(token) {

        let result = await GET_EVALUATION_ORDER(token)
        if (result?.status === 200) {
            setOrder(result.data?.order)
            setName({ value: result.data?.order?.name, label: result.data?.order?.name })
        } else {
            setTokenExpired(true)
        }
        setLoading(false)
    }

    const createEvaluation = async () => {

        let data = {
            name: name.value,
            quality: quality,
            delivery: delivery,
            comment: comment,
            author: order?.client?._id,
            order: order?._id,
        }


        setUpdating(true)
        let result = await EVALUATE_ORDER(order?._id, data)
        if (result.status === 200) {
            setOrderRated(true)
        } else {
            handleToast(result, "error")
        }
        setUpdating(false)
    };

    function handleToast(text, type) {
        enqueueSnackbar(text, {
            variant: type,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        })
    }

    return (
        <AuthLayout2 shopImage={order?.shop?.image}>
            {isLoading &&
                <CircularProgress color="primary" style={{ height: 10 }} />
            }
            {!isLoading && !isOrderRated && !isTokenExpired &&
                <VerticalForm
                    formClass="authentication-form">
                    <h6 className="h5 mb-0 mt-3">Évaluer votre commande</h6>
                    <p className="mb-0 mt-3">{moment(order?.createdAt).format('DD MMMM YYYY')}</p>
                    <p className="mb-0">Commande <b>#{order?.reference}</b></p>
                    <p className="mb-2">Total : <b>{order?.total} €</b></p>

                    <Row className="d-flex align-items-center justify-content-center mb-2">
                        <Col>
                            <p className="h5 mb-0">Nom</p>
                        </Col>
                        <Col>
                            <Select
                                className="react-select react-select-container"
                                classNamePrefix="react-select"
                                options={[
                                    { value: order?.name, label: order?.name },
                                    { value: 'Anonyme', label: 'Anonyme' },
                                ]}
                                onChange={(e: any) => {
                                    setName(e)
                                }}
                                value={name}
                            />
                        </Col>
                    </Row>

                    <Row className="d-flex align-items-center justify-content-center">
                        <Col>
                            <p className="h5 mb-0">Nouriture</p>
                        </Col>
                        <Col>
                            <ReactStars
                                count={5}
                                onChange={(value) => setQuality(value)}
                                value={quality}
                                size={24}
                                activeColor="#ffd700"
                            />
                        </Col>
                    </Row>

                    <Row className="d-flex align-items-center justify-content-center mb-2">
                        <Col>
                            <p className="h5 mb-0">Livraison</p>
                        </Col>
                        <Col>
                            <ReactStars
                                count={5}
                                onChange={(value) => setDelivery(value)}
                                value={delivery}
                                size={24}
                                activeColor="#ffd700"
                            />
                        </Col>
                    </Row>

                    <textarea
                        rows={5}
                        className="form-control border-1 resize-none mb-3"
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Votre commentaire ...">

                    </textarea>

                    <div className="text-center d-grid">
                        <Button onClick={() => createEvaluation()} disabled={isUpdating}>
                            Envoyer
                        </Button>
                    </div>
                    {isUpdating && <LinearProgress color="secondary" style={{ height: 5 }} />}
                </VerticalForm>
            }

            {isOrderRated && !isTokenExpired &&
                <Alert variant="success">Merci, la commande est evaluée avec succès.</Alert>
            }

            {isTokenExpired &&
                <Alert variant="danger">Lien d'évaluation expiré.</Alert>
            }
        </AuthLayout2>
    );
};

export default RateOrder;