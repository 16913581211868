import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Common from './common/reducers';

export default combineReducers({
    Auth,
    Layout,
    Common
});
