import { APICore } from '../helpers/api/apiCore';
import { _SHOPS } from '../constants/links';

const api = new APICore();

// account
function GET_SHOPS() {
    const baseUrl = _SHOPS;
    return api.get(`${baseUrl}`);
}

function GET_SHOP(shopName) {
    const baseUrl = _SHOPS + "name/" + shopName;
    return api.get(`${baseUrl}`);
}

function ADD_SHOP(data) {
    const baseUrl = _SHOPS;
    return api.create(`${baseUrl}`, data);
}

function UPDATE_SHOP(idShop, data) {
    const baseUrl = _SHOPS + idShop;
    return api.update(`${baseUrl}`, data);
}

function UPDATE_SHOP_STATUS(idShop, data) {
    const baseUrl = _SHOPS + "status/" + idShop;
    return api.update(`${baseUrl}`, data);
}

export { GET_SHOPS, GET_SHOP, ADD_SHOP, UPDATE_SHOP, UPDATE_SHOP_STATUS };
