import { _ADMINS } from '../constants/links';
import { APICore } from '../helpers/api/apiCore';

const api = new APICore();

// account
function GET_ADMINS() {

    const baseUrl = _ADMINS;
    return api.get(`${baseUrl}`);
}

function ADD_ADMIN(data) {

    const baseUrl = _ADMINS;
    return api.create(`${baseUrl}`, data);
}

function UPDATE_ADMIN(idAdmin, data) {

    const baseUrl = _ADMINS + idAdmin;
    return api.update(`${baseUrl}`, data);
}

function DELETE_ADMIN(idAdmin, data) {

    const baseUrl = _ADMINS + idAdmin;
    return api.delete(`${baseUrl}`, data);
}

function RESET_PASSWORD(idAdmin, data) {

    const baseUrl = _ADMINS + "reset-password/" + idAdmin;
    return api.update(`${baseUrl}`, data);
}

function GET_RESET_PASSWORD_ADMIN(token) {

    const baseUrl = _ADMINS + "reset-password-admin/" + token;
    return api.get(`${baseUrl}`, {});
}


export { GET_ADMINS, ADD_ADMIN, UPDATE_ADMIN, DELETE_ADMIN, RESET_PASSWORD, GET_RESET_PASSWORD_ADMIN };
