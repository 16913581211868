

export const _STATISTICS = "back/statistics/"
export const _ORDERS = "back/orders/"
export const _CLIENTS = "back/clients/"
export const _COMMENTS = "back/comments/"
export const _ADMINS = "back/admin/"
export const _SHOPS = "back/shops/"
export const _SLOTS = "back/slots/"
export const _COUPONS = "back/coupons/"
export const _ROLES = "back/roles/"
export const _BTIPLINK = "back/btiplink/"
export const _PARAMETERS = "back/parameters/"
export const _INVOICES = "back/invoices/"
export const _SUBSCRIPTION_PACKS = "/back/subscription-packs/"
export const _CATEGORIES = "back/categories/"
export const _INGREDIENTS = "back/ingredients/"
export const _PRODUCTS = "back/products/"
export const _MENUS = "back/menus/"
export const _LOYALTY_POINTS_PRODUCTS = "back/loyalty-points-products/"