export interface CommonStateTypes {
  currentShop: any;
}

enum CommonActionTypes {
  CURRENT_SHOP = "@@common/SET_CURRENT_SHOP",
  GET_SHOP = "@@common/GET_SHOP",
  URL_SHOP_NAME = "@@common/URL_SHOP_NAME",
  GET_SHOPS = "@@common/GET_SHOPS",
  RESET = "@@common/RESET",
  SAVE_SOCKET = "@@common/SOCKET",
}

export { CommonActionTypes };
