// constants
import { CommonActionTypes } from "./constants";

export interface CommonActionType<TPayload> {
  type:
    | CommonActionTypes.CURRENT_SHOP
    | CommonActionTypes.GET_SHOP
    | CommonActionTypes.GET_SHOPS
    | CommonActionTypes.RESET
    | CommonActionTypes.SAVE_SOCKET
    | CommonActionTypes.URL_SHOP_NAME;
  payload?: TPayload;
}

export const setCurrentShop = (shop: object): CommonActionType<object> => ({
  type: CommonActionTypes.CURRENT_SHOP,
  payload: shop,
});

export const getShop = (name: string): CommonActionType<object> => ({
  type: CommonActionTypes.GET_SHOP,
  payload: { name },
});

export const setShops = (shops: [object]): CommonActionType<[object]> => ({
  type: CommonActionTypes.GET_SHOPS,
  payload: shops,
});

export const setUrlShopName = (shopName: string): CommonActionType<string> => ({
  type: CommonActionTypes.URL_SHOP_NAME,
  payload: shopName,
});

export const resetStates = () => ({
  type: CommonActionTypes.RESET,
  payload: null,
});

export const saveSocket = (socket: object): CommonActionType<object> => ({
  type: CommonActionTypes.SAVE_SOCKET,
  payload: socket,
});
