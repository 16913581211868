import React from 'react';

import Routes from './routes/Routes';

import { configureFakeBackend } from './helpers';

// Themes
// For Default import Theme.scss
import './assets/scss/Theme.scss';
import axios from 'axios';

// For Dark import Theme-Dark.scss
// import './assets/scss/Theme-Dark.scss';

// configure fake backend
// configureFakeBackend();

const App = () => {
    
    // function loadapi(){
    //     var config = {
    //         method: 'post',
    //         url: 'http://192.168.1.1:4000/back/admin/login',
    //         data: { email: "admin@lostivale.com", password: "admin123"}
    //       };
          
    //       axios(config)
    //       .then(function (response) {
    //         console.log(response.data);
    //       })
    //       .catch(function (error) {
    //         console.log("error");
    //         console.log(error);
    //         console.log(error.response);
    //       });
          
    // }
    // loadapi()
    return (
        <>
            <Routes></Routes>
        </>
    );
};

export default App;
