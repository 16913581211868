import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { APICore } from '../helpers/api/apiCore';

const Root = () => {

    const api = new APICore();
    const loggedInUser = api.getLoggedInUser();
    const isSuperAdmin = loggedInUser?.role?.name === "ROLE_SUPER_ADMIN"
    const { currentShop } = useSelector((state: RootState) => ({
        currentShop: state.Common.currentShop
    }));

    const getRootUrl = () => {
        let url: string = 'auth/login';

        // check if user logged in or not and return url accordingly
        if (api.isUserAuthenticated() === false) {
            url = 'auth/login';
        } else {
            url = (isSuperAdmin && currentShop === null)
                ? 'admin/dashboard'
                : 'shop/' + currentShop?.name + '/dashboard';
        }
        return url;
    };

    const url = getRootUrl();
    return <Redirect to={`/${url}`} />;
};

export default Root;
