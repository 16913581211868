import { _ORDERS, _COMMENTS } from '../constants/links';
import { APICore } from '../helpers/api/apiCore';

const api = new APICore();

function GET_ORDERS_PERIOD_DETAILS(idShop, startDate, endDate) {

    const baseUrl = _ORDERS + "get-total/" + idShop + "/start/" + startDate + "/end/" + endDate
    return api.get(`${baseUrl}`);
}

function GET_CLIENT_LOYALTY_POINTS_ORDERS(idClient, idShop) {

    const baseUrl = _ORDERS + "loyalty-points/" + idClient + "/shop/" + idShop
    return api.get(`${baseUrl}`);
}

function GET_CLIENT_ORDERS(idClient) {

    const baseUrl = _ORDERS + "client/" + idClient
    return api.get(`${baseUrl}`);
}

function GET_ORDERS_BY_DATE(data, idShop) {

    const baseUrl = _ORDERS + "shop/" + idShop + "/date-new";
    return api.create(`${baseUrl}`, data);
}

function ARCHIVE_PAID_ORDERS(data) {

    const baseUrl = _ORDERS + "update-cash-archived";
    return api.update(`${baseUrl}`, data);
}

function GET_CLIENT_SHOP_ORDERS_BY_DATE(data, idShop) {

    const baseUrl = _ORDERS + "shop/" + idShop + "/clients/date-new";
    return api.create(`${baseUrl}`, data);
}

function GET_ORDER_DETAILS(idOrder) {

    const baseUrl = _ORDERS + idOrder
    return api.get(`${baseUrl}`);
}

function GET_EVALUATION_ORDER(token) {

    const baseUrl = _COMMENTS + "evalute/order/" + token
    return api.get(`${baseUrl}`);
}

function GET_ORDERS_TOTAL_COUNT(idShop) {

    const baseUrl = _ORDERS + "shop/" + idShop + "/total";
    return api.get(`${baseUrl}`);
}

function GET_ORDERS_YEAR_MONTHS_TOTALS(idShop) {

    const baseUrl = _ORDERS + "shop/" + idShop + "/month-total";
    return api.get(`${baseUrl}`);
}

function GET_RECENT_ORDERS(idShop) {

    const baseUrl = _ORDERS + "shop/" + idShop + "/recent";
    return api.get(`${baseUrl}`);
}

function GET_ORDERS_ADDRESSES(idShop) {

    const baseUrl = _ORDERS + "shop/" + idShop + "/addresses";
    return api.get(`${baseUrl}`);
}

function GET_ORDERS_BY_SOURCE() {

    const baseUrl = _ORDERS + "group/by-sources";
    return api.get(`${baseUrl}`);
}

function UPDATE_ORDER_STATUS(idOrder, data) {

    const baseUrl = _ORDERS + "update/status/" + idOrder;
    return api.create(`${baseUrl}`, data);
}

function UPDATE_DELIVERY_TIME(idOrder, data) {

    const baseUrl = _ORDERS + "update/deliverytime/" + idOrder;
    return api.create(`${baseUrl}`, data);
}

function REQUEST_ORDER_REFUND(data) {

    const baseUrl = _ORDERS + "refund-request";
    return api.create(`${baseUrl}`, data);
}

function EVALUATE_ORDER(idOrder, data) {

    const baseUrl = _COMMENTS + "evaluate-order/" + idOrder;
    return api.update(`${baseUrl}`, data);
}

export { GET_ORDERS_TOTAL_COUNT, EVALUATE_ORDER, ARCHIVE_PAID_ORDERS, GET_CLIENT_SHOP_ORDERS_BY_DATE, REQUEST_ORDER_REFUND, UPDATE_ORDER_STATUS, GET_CLIENT_LOYALTY_POINTS_ORDERS, UPDATE_DELIVERY_TIME, GET_CLIENT_ORDERS, GET_ORDER_DETAILS, GET_ORDERS_YEAR_MONTHS_TOTALS, GET_RECENT_ORDERS, GET_ORDERS_ADDRESSES, GET_ORDERS_BY_SOURCE, GET_ORDERS_BY_DATE, GET_ORDERS_PERIOD_DETAILS, GET_EVALUATION_ORDER };
